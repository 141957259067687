import { Link } from "react-router-dom";
import Layout from "./Layout";
import venueIcon from "../assets/images/venue-icon.svg";
import venue from "../assets/images/venue-icon.svg";
import config from '../config';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";

const EventIndex = () => {    
    const [events, setEvents] = useState([]);
    const [fullWidthEvent, setFullWidthEvent] = useState();

    const fetchEvents = async () => {
        try {
           const response = await axios.get(`${config.BASE_URL}/api/admin/get-events`, { withCredentials: true });
           
            if(response.data){
                response.data.length > 0 && response.data.map((item, key) => {
                    if(item.isFullWidth){
                        setFullWidthEvent(item);
                    }
                });

                setEvents(response.data);
            }
  
        } catch (err) {
            console.log('Failed to fetch events');
        }
    };

    useEffect(() => {
        fetchEvents();
     }, []);

  return (  
    <Layout>  
    <>
        <div class="event-index template-index pt-220">            
            <div className="title-block center pb-40 m-pb-10" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                <div className="container">
                    <h6>Recent</h6>
                    <h1>Events</h1>
                </div>
            </div>
            <div className="title-with-line"></div>
            <div className="events-section pt-100 pb-50 m-pt-40 m-pb-0" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                <div className="container">
                    <div className="event-listing py-100">
                        {(events && events.length > 0) && events.map((item, key) => (
                            <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center color-light position-relative" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                <div className="hero-banner">
                                    <Link to={`/single-event/${item?.slug}`}><img width="" height="" src={config.BASE_URL + item?.featuredImage} alt="" /></Link>
                                </div>
                                <div className="hero-summary container-summary center position-absolute">
                                    <Link to={`/single-event/${item?.slug}`}>
                                        <div className="container-summary-wrapper m-auto w-100">                                    
                                            <div className="title-block">{item?.title}</div>
                                            <div className="divider position-relative">at</div>
                                            <div className="summary-wrapper">
                                                <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>
                                                <div className="title-block location">{item?.venueTitle}</div>                                    
                                                <div className="cursive-title-block organizer">{item?.venueLocation}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="text text-left cursive-title-block position-absolute">{item?.overlayText}</div>
                            </div>
                        ))}
                        {/* <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center center color-light position-relative">
                            <div className="hero-banner">
                                <img width="" height="" src={venuelistImg2} alt="" />
                            </div>
                            <div className="hero-summary container-summary position-absolute">
                                <div className="container-summary-wrapper m-auto w-100">                                    
                                    <div className="title-block">old course hotel</div>
                                    <div className="divider position-relative">at</div>                                    
                                    <div className="summary-wrapper">
                                        <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>                                    
                                        <div className="title-block location">FIFE</div>                                    
                                        <div className="cursive-title-block organizer">St. Andrews</div>
                                    </div>
                                </div>
                            </div>
                            <div className="text text-right cursive-title-block color-dark position-absolute">golf history</div>
                        </div> */}
                    </div>                    
                </div>
            </div>

            {fullWidthEvent && (
                <>
                    <div className="event-hero-section video-section full-screen hero-section d-flex justify-content-center bottom-30 bottom-overlay-dark position-relative">
                        <div className="hero-banner">
                            <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={config.BASE_URL + fullWidthEvent.featuredVideo} type="video/mp4" /></video>
                        </div>
                        <div className="hero-summary d-flex flex-column justify-content-end align-items-center center color-light position-absolute h-100">                
                            <div className="top-summary color-light position-absolute">
                                <div className="logo-block"><img width="" height="" src={venue} alt="" /></div>     
                                <div className="title-block"><h5>{fullWidthEvent?.venueTitle}</h5></div>
                            </div>
                            <div className="title-block"><h2>{fullWidthEvent?.venueLocation}</h2></div>                
                        </div>                
                    </div>
                    <div className="events-section pt-180 pb-30 m-pt-50 m-pb-0">
                        <div className="container">
                            <div className="event-listing">
                                <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center color-light position-relative">
                                    <div className="hero-banner">
                                        <img width="" height="" src={config.BASE_URL + fullWidthEvent.featuredImage} alt="" />
                                    </div>
                                    <div className="hero-summary container-summary center position-absolute">
                                        <Link to={`/single-event/${fullWidthEvent?.slug}`}>
                                            <div className="container-summary-wrapper m-auto w-100">
                                                
                                                <div className="title-block">{fullWidthEvent.title}</div>                                    
                                                <div className="divider position-relative">at</div>
                                                <div className="summary-block">
                                                    <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>
                                                    <div className="title-block location">{fullWidthEvent?.venueTitle}</div>
                                                    <div className="cursive-title-block organizer">{fullWidthEvent?.venueLocation}</div>                                    
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="text text-left cursive-title-block position-absolute">{fullWidthEvent.overlayText}</div>
                                </div> 
                            </div>                            
                        </div>
                    </div>
                </>
            )}
        </div>  
        
    </>
    </Layout>
  );
};

export default EventIndex;
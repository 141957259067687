
import './App.css';
import './assets/css/custom.css';
import './assets/css/style.css';
import './assets/fonts/fonts.css';
import 'swiper/css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import ProtectedRoute from './components/admincomponents/ProtectedRoutes';
import Dashboard from './pages/admin/Dashboard';
import AdminLogin from './components/admincomponents/AdminLogin';

// events
import AdminEvents from './pages/admin/AdminEvents';
import ViewEvent from './pages/admin/ViewEvent';
import UpdateEvent from './pages/admin/UpdateEvent';
import AddEvent from './pages/admin/AddEvent';

// venues
import AdminVenues from './pages/admin/AdminVenues';
import ViewVenue from './pages/admin/ViewVenue';
import UpdateVenue from './pages/admin/UpdateVenue';
import AddVenue from './pages/admin/AddVenue';

import Settings from "./pages/admin/Settings";


import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";

import SingleVenue from "./pages/SingleVenue.jsx";
import SingleEvent from "./pages/SingleEvent.jsx";
import VenueIndex from "./pages/VenueIndex.jsx";
import EventIndex from "./pages/EventIndex.jsx";
import Contact from "./pages/Contact.jsx";
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isAdminRoute = window.location.pathname.startsWith('/admin');

    useEffect(() => {
        const body = document.querySelector("body");
        if (isAdminRoute) {
            body.classList.add("adminPanel");
        } else {
            body.classList.remove("adminPanel");
            // import('./assets/css/style.css');
        }

        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }

        // setTimeout(() => {

            setIsLoading(false); // Stop loading after checking token
        // })
    }, []);

    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
    };

    if (isLoading) {
        return null;
    }

    return (
        <>
            <PrimeReactProvider>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/single-venue/:id" element={<SingleVenue />} />
                    <Route path="/single-event/:id" element={<SingleEvent />} />
                    <Route path="/venues" element={<VenueIndex />} />
                    <Route path="/events" element={<EventIndex />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    


                    {/* admin routes */}
                    <Route
                        path="/admin/dashboard"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <Dashboard onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/login"
                        element={
                            isAuthenticated ? (
                                <Navigate to="/admin/dashboard" />
                            ) : (
                                <AdminLogin onLogin={handleLogin} />
                            )
                        }
                    />

                    <Route
                        path="/admin/venues"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <AdminVenues onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/add-venue"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <AddVenue onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/venue/:id"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <ViewVenue onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/update-venue/:id"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <UpdateVenue onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/events"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <AdminEvents onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/add-event"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <AddEvent onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/event/:id"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <ViewEvent onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/update-event/:id"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <UpdateEvent onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/admin/settings"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <Settings onLogout={handleLogout} />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </PrimeReactProvider>
        </>
    );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Layout from "./Layout";
import contactBanner from "../assets/images/contact-banner.jpg";
import { FaEnvelope } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import axios from 'axios';
import config from "../config"

const Contact = () => {
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setIsLoading(true);
            const response = await axios.post(`${config.BASE_URL}/api/admin/send-email`, formData, { withCredentials: true });
            if (response) {
                setIsMailSent(true);

                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
            }

            setIsLoading(false);

            setTimeout(() => {
                setIsMailSent(false);
            }, 5000);
        } catch (error) {
            console.error("Error fetching blogs:", error);
            setIsMailSent(false);
            setIsLoading(false);
        }
    };


    return (
        <Layout showFooter={false}>
            <>
                <div className="contact-section full-screen m-full-screen-auto">
                    <div className="contact-wrapper d-grid full-screen">
                        <div className="contact-left d-flex flex-column color-light bottom-overlay-dark position-relative full-screen m-full-screen-auto bg-cover" style={{ backgroundImage: `url(${contactBanner})` }}>
                            <div className="text-block text-top" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                <h1>START<br /> PLANNING</h1>
                                <p>get in touch with our team to start planning your Caledonia Event.</p>
                            </div>
                            <div className="text-block text-bottom pb-30" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                <div className="heading-block pb-30">GET IN TOUCH</div>
                                <div className="list-block">
                                    <ul className="list-style-none d-grid">
                                        <li><FaEnvelope /> <Link to="mailto:info@caledoniaevents.com">info@caledoniaevents.com</Link></li>
                                        <li><FaInstagram /> @caledoniaevents</li>
                                        {/* <li><IoIosPhonePortrait /> <Link to="tel:+1 - 111- 11111">+1 - 111- 11111</Link></li> */}
                                        

                                        {/* <li>Unit 2 </li> */}
                                        
                                    </ul>

                                    <div className='office-address d-grid'>
                                        <div className='text-block'>Unit 2 </div>
                                        <div className='text-block'>Corunna place</div>
                                        <div className='text-block'>Edinburgh</div>
                                        <div className='text-block'>EH6 5JG</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-right d-flex justify-content-center full-screen m-full-screen-auto">
                            <div className="form-block" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                <div className="text-block pb-30">
                                    <p>The event planning team at Caledonia Events would love to hear from you! If you are enquiring about our wedding or party planning services, please use the enquiry form on the right, email us, or call the number provided.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-field">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="your name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-field">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="your email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-field">
                                        <input
                                            type="tel"
                                            name='phone'
                                            placeholder="your number"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-field">
                                        <textarea 
                                            type="" 
                                            placeholder="your message" 
                                            name='message'
                                            value={formData.message}
                                            onChange={handleChange}
                                            required 
                                        ></textarea>
                                    </div>
                                    <div className="submit-field end">
                                        {isMailSent && (<><p style={{ color: "#fff" }}>Message Sent!</p></>)}
                                        <button type="submit" className='submit-button' disabled={isLoading}>SEND</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
};

export default Contact;
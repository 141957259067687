import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import menuToggle from "../assets/images/menu-toggle.svg";
import MobileLogo from "../assets/images/icons/MobileLogo";
import { useLocation, useNavigate } from "react-router-dom";

import close from "../assets/images/close.svg";
const Home = ({servicesRef}) => { 
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { hash } = useLocation();
  const navigate = useNavigate();

  const toggleNav = () => {
    setIsNavOpen(true);
  }
  const closeNav = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };
  
  const offset = window.innerWidth <= 992 ? 130 : 0; // Adjust the offset value as needed

  useEffect(() => {
    if(hash != "" && hash === "#services"){
      if (servicesRef.current) {
        const elementPosition = servicesRef.current.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        // servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });

        setIsNavOpen(false);
      }
    }
  }, [hash]);

  useEffect(() => {
    if(hash != "" && hash === "#services"){
      setTimeout(() => {
        if (servicesRef.current) {
          const elementPosition = servicesRef.current.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;

          // servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }, 500);
    }
  }, []);

  const [scrolled, setScrolled] = useState(false);

  useState(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (    
    <header className={`site-header position-absolute w-100 ${ scrolled ? "header-overlay" : ""}`}>
        <div className="container">
            <div className="mobile-head justify-content-between">
              <div className="left-block">
                <div className="mobile-logo"><Link to="/">{<MobileLogo />}</Link></div>
              </div>
              <div className="right-block d-flex align-items-center">
                <div className="contact-menu"><Link to="/contact">Contact</Link></div>
                <div className="toggle-button d-flex align-items-center" onClick={toggleNav}><img width="" height="" src={menuToggle} alt="" /></div>
              </div>
            </div>
            <nav className={`site-nav ${isNavOpen ? "open-menu" : ""}`}>
                <ul className="d-flex justify-content-center">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/#services">What We Do</Link></li>
                    <li><Link to="/venues">Venues</Link></li>
                    <li><Link to="/events">Portfolio</Link></li>                    
                    <li><Link to="/contact">start</Link></li>
                </ul>
                <div className="close-menu position-absolute" onClick={closeNav}><img width="" height="" src={close} alt="" /></div>
            </nav>
        </div>        
    </header>
  );
};

export default Home;
import React, {useEffect} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FooterBottom from "../components/FooterBottom";
import { useLocation } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = ({ children, extraClass="", showFooter=true, servicesRef }) => {
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header servicesRef={servicesRef}/>
        <div className={`site-content ${extraClass}`}>
          {children}
        </div>
        {showFooter && (
          <Footer />          
        )}
        <FooterBottom />
    </>
  );
};

export default Layout;



import Layout from "./Layout";
import heroVideo from "../assets/videos/home-hero-video.mp4";
import celeBrates from "../assets/images/celebrates.jpg";
import courseHotel from "../assets/images/old-course-hotel.svg";
import courseHotelDark from "../assets/images/old-course-hotel-dark.svg";
import weddingPlanning from "../assets/images/wedding-planning.jpg";
import scotlandAdventures from "../assets/images/scotland-adventures.jpg";
import brandEvents from "../assets/images/brand-events.png";
// import servicesVideo from "../assets/videos/services-video.mp4";
import servicesVideo from "../assets/videos/caledonia-events-services-video.mp4";
import partyPlanning from "../assets/videos/party-planning-video.mp4";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import BannerLogo from "../assets/images/icons/BannerLogo";

import config from '../config';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";

const Home = () => {
   const [isFullWidth, setIsFullWidth] = useState();
   const [featuredVenue, setFeaturedVenue] = useState();
   const [featuredEvent, setFeaturedEvent] = useState();
   const [venues, setVenues] = useState([]);
   const [events, setEvents] = useState([]);
   const [activeTab, setActiveTab] = useState("services");

   const servicesRef = useRef(null);
   const eventsRef = useRef(null);
   const venuesRef = useRef(null);

   const [error, setError] = useState('');
   const [successMessage, setSuccessMessage] = useState('');

   const fetchVenues = async () => {
      try {
          const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
          
          if(response.data){
               let fVenue = false
               await Promise.all(response?.data?.length > 0 && response.data.map(item => {
                     if(item.isFeatured){
                        fVenue = item;
                     }
               }));

               if(!fVenue){
                  setFeaturedVenue(response.data[0]);
                  response.data.shift();
                  setVenues(response.data);
               }else{
                  setFeaturedVenue(fVenue);
                  setVenues(response.data);
               }

          }
  
      } catch (err) {
          console.log('Failed to fetch venues');
      }
   };

   // console.log("featuredVenue: ", featuredVenue);

   const fetchEvents = async () => {
         try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-events`, { withCredentials: true });
            
            if(response.data){
               var featured = false;
               await Promise.all(response?.data?.length > 0 && response.data.map(item => {
                     if(item.isFeatured){
                        featured = item;
                     }
               }));

               console.log("featuredEvent in api: ", featured);

               if(!featured){
                  setFeaturedEvent(response.data[0]);
                  response.data.shift();
                  setEvents(response.data);
               }else{
                  setFeaturedEvent(featured);
                  setEvents(response.data);
               }
               
            }
   
         } catch (err) {
            console.log('Failed to fetch events');
         }
   };

   const fetchFullWidthVenue = async () => {
         try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-full-width-venue`, { withCredentials: true });
            
            if(response.data){
               setIsFullWidth(response.data);
            }
   
         } catch (err) {
            console.log('Failed to fetch venues');
         }
   };

   const toggleTab = (e, tabName, ref) => {
      e.preventDefault();

      setActiveTab(tabName);
      if (ref.current) {
         ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
   }

   useEffect(() => {
      fetchVenues();
      fetchEvents();
      fetchFullWidthVenue();
   }, []);

   

   class Tabs extends React.Component{
      state ={
        activeTab: this.props.children[0].props.label
      }
      changeTab = (tab) => {
    
        this.setState({ activeTab: tab });
      };
      render(){
        
        let content;
        let buttons = [];
        return (
          <div>
            {React.Children.map(this.props.children, child =>{
              buttons.push(child.props.label)
              if (child.props.label === this.state.activeTab) content = child.props.children
            })}
             
            <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
            <div className="tab-panel position-relative">{content}</div>
            
          </div>
        );
      }
    }

    const TabButtons = ({buttons, changeTab, activeTab}) =>{
   
      return(
        <div className="tab-nav d-flex justify-content-center pb-60 m-pb-30">
        {buttons.map(button =>{
           return <button className={button === activeTab? 'active': ''} onClick={()=>changeTab(button)}>{button}</button>
        })}
        </div>
      )
    }
    
    const Tab = props =>{
      return(
        <React.Fragment>
          {props.children}
        </React.Fragment>
      )
    }

  return (
  <Layout servicesRef={servicesRef} >
     <div className="hero-section home-hero-section full-screen center color-light d-flex justify-content-center align-items-center bottom-overlay-dark position-relative">
        <div className="hero-banner w-100 h-100">
           <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={heroVideo} type="video/mp4" /></video>           
        </div>
        <div className="hero-summary position-absolute" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
           <div className="image-block m-auto pb-40">{<BannerLogo />}</div>
           <div className="title-block">Caledonia Events</div>           
        </div>
        <div className="nav-block position-absolute" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
           <ul className="list-style-none">
              <li>
                 <Link to="#">
                 Private Events</Link>
              </li>
              <li>
                 <Link to="#">
                 Weddings</Link>
              </li>
              <li>
                 <Link to="#">
                 Receptions</Link>
              </li>
              <li>
                 <Link to="#">
                 And More</Link>
              </li>
           </ul>
        </div>
     </div>
     <div className="image-overlap-sidetext-section py-120" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
        <div className="container">
           <div className="container-500 m-auto">
              <div className="title-block center m-auto pb-50">
                 <h5>Modern Moments Celebrated With Tradition</h5>
              </div>
              <div className="image-block position-relative">
                 <img width="" height="" src={celeBrates} alt="" />
                 <div className="text text-left cursive-title-block position-absolute">family tartan</div>
                 <div className="text text-right cursive-title-block position-absolute">thisktles onthe tables</div>
              </div>
           </div>
        </div>
     </div>
     <div id="services" className="title-with-line" ref={servicesRef}>
        <div className="container" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
           <div className="title-with-line-wrapper">
              <h3 className="cursive-title-block">welcome</h3>
           </div>
        </div>
     </div>
     <div className="tab-section-scroll" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
        <ul className="list-style-none d-flex justify-content-center">
           <li><Link to="#" onClick={(e) => toggleTab(e, "services", servicesRef)} className={`${activeTab === "services" ? 'active' : ''}`}>Services</Link></li>
           <li><Link to="#featured-events" onClick={(e) => toggleTab(e, "events", eventsRef)} className={`${activeTab === "events" ? 'active' : ''}`}>Events</Link></li>
           <li><Link to="#featured-venues" onClick={(e) => toggleTab(e, "venues", venuesRef)} className={`${activeTab === "venues" ? 'active' : ''}`}>Venues</Link></li>
        </ul>
     </div>
     <div className="hero-section full-screen d-flex justify-content-center bottom-150 color-light center bottom-overlay-dark position-relative" data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
        <div className="hero-banner">
         <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={servicesVideo} type="video/mp4" /></video>
        </div>
        <div className="hero-summary position-absolute">          
           <h2>Tailored<br /> Experiences</h2>
           <p>curated by local experts.</p>
        </div>
     </div>

     <div className="tab-section color-light bg-black py-80 m-pb-10" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div className="container">
         <div className="tab-section-wrapper">            
            <Tabs>
               <Tab label="Wedding Planning">
                  <div className="image-block">
                     <img width="" height="" src={weddingPlanning} alt="" />
                  </div>   
                  <div className="text text-left cursive-title-block position-absolute">wedding planning</div>
                  <div className="text-block position-absolute">
                     <p>Scotland’s romance, colourful history, and traditions are unmatched and enchanting. We take  immense pride in our wedding planning services to help couples, both destination and local, lean  into the beauty of this setting with style on their wedding day.</p>  
                     <p>We’ll manage every aspect of the planning, allowing you to fully enjoy being a guest at your own  wedding. Let us capture your dream, find the perfect venue, and tailor every detail, giving you and  your guests the most momentous celebration of your lives.</p>
                  </div>
               </Tab>
               <Tab label="Party Planning">
                  <div className="video-block">
                     <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={partyPlanning} type="video/mp4" /></video>                     
                  </div>               
                  <div className="text text-left cursive-title-block position-absolute">party planning</div>
                  <div className="text-block position-absolute">
                     <p>Working in luxury events means the world is our oyster–but for us, Scotland is the beloved pearl. We’ve planned and delivered parties in cobbled crescents, dinners in lively cultural centres, expeditions across our Celtic countryside, and weddings at magnificent clan castles.Everyone has their vision and we nurture this with our tailored approach, putting your desires at the heart of our delivery. From intimate luxury dinner parties to large-scale wedding planning and guest logistics, our management expertise is unparalleled. Combined with our cherished connections with the finest local suppliers and venues, we create experiences as warm and welcoming as Scotland itself. </p> 
                  </div>
               </Tab>
               <Tab label="Scotland Adventures">
                  <div className="image-block">
                  <img width="" height="" src={scotlandAdventures} alt="" />
                  </div>               
                  <div className="text text-left cursive-title-block position-absolute">adventures</div>
                  <div className="text-block position-absolute">
                     <p>We know Scotland and understand luxury–Scottish culture is rich, the traditions are timeless, and  our insider knowledge allows us to curate experiences and events that demonstrate just that. Whether you’re planning a destination wedding or luxury private event, we can help you explore  Scotland’s dramatic landscape in style. </p>  
                     <p>Board the Royal Scotsman train, soar above the Highlands  by helicopter, or get up close and personal with guided expeditions of the glens, lochs, and castles–perhaps finishing the day with a private whisky tasting. With every detail managed for you,  we guarantee highly exclusive experiences that are authentic and showcase Scotland’s unique spirit.</p>
                  </div>
               </Tab>
               <Tab label="Brand Events">
                  <div className="image-block">
                  <img width="" height="" src={brandEvents} alt="" />
                  </div>               
                  <div className="text text-left cursive-title-block position-absolute">Brand Events</div>
                  <div className="text-block position-absolute">
                  <p>To be a luxury brand is to be bold and impressive. Against the backdrop of Scotland, we create  design-led brand events with impact and meaning. We flex our expertise in guest experience to  create environments ripe for making connections and leaving lasting impressions. Working closely  with you and our network of trusted local suppliers, we’ll plan an event that captures the distinctive  essence of your brand.</p>                    
                  </div>
               </Tab>
            </Tabs>
         </div>
      </div>
     </div>
    
     <div id="featured-events" className="featured-events events-section center pt-90" ref={eventsRef}>
        <div className="container m-px-0" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
           <div className="title-block center pb-80">
              <h6>Featured</h6>
              <h2>Events</h2>
           </div>
           {featuredEvent && (
               <div className="event-listing">
                  <div className="grid-row">
                     <div className="grid-item event-item hero-section container-hero-section d-flex justify-content-center position-relative">
                        <div className="hero-banner">
                           <Link to={`/single-event/${featuredEvent?.slug}`}>
                              <img className="w-100" width="" height="" src={config.BASE_URL + featuredEvent?.featuredImage} alt="" />
                           </Link>
                        </div>
                        <div className="text text-right cursive-title-block position-absolute">{featuredEvent?.overlayText}</div>       
                           <div className="hero-summary container-summary color-light position-absolute">
                        <Link to={`/single-event/${featuredEvent?.slug}`}>
                              <div className="title-block">{featuredEvent?.title}</div>
                              <div className="divider position-relative"><em>at</em></div>                      
                                 <div className="summary-wrapper">
                                    <div className="logo-block"><img width="" height="" src={courseHotel} alt="" /></div>
                                    <div className="title-block location">{featuredEvent?.venueTitle}</div>                      
                                    <div className="cursive-title-block organizer">{featuredEvent?.venueLocation}</div>                    
                              </div>
                        </Link>
                           </div> 
                     </div> 
                  </div>           
               </div>
           )}
           <div className="event-items py-100">
              <div className="grid-row">
              <Swiper
                spaceBetween={20}
                loop={true}
                centeredSlides={true}              
                slidesPerView={1.4}
                initialSlide={2}
                breakpoints={{                                                
                993:{   
                loop: false,                                  
                centeredSlides: false,                 
                slidesPerView: 3,
                spaceBetween: 30,
                },
                }}
                >
                  {(events && events.length) > 0 && events.map((item, key) => (
                     <SwiperSlide key={key}>
                        <div className="grid-item">
                           <Link to={`/single-event/${item?.slug}`}>
                              <div className="image-block"><img className="w-100" width="" height="" src={config.BASE_URL + item?.featuredImage} alt="" /></div>
                              <div className="title-block">{item?.title}</div>
                              <div className="divider position-relative"><em>at</em></div>                  
                              <div className="logo-block"><img width="" height="" src={courseHotelDark} alt="" /></div>
                              <div className="title-block location">{item?.venueTitle}</div>
                              <div className="cursive-title-block organizer">{item?.venueLocation}</div>                  
                           </Link>
                        </div>
                     </SwiperSlide>
                  ))}
                </Swiper>
              </div>
           </div>
        </div>
     </div>
     <div id="featured-venues" className="featured-venues venues-section center" ref={venuesRef}>
      {featuredVenue && (
         <div className="grid-item hero-section container-hero-section color-light d-flex justify-content-center top-bottom-overlay position-relative" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
            <div className="title-block position-absolute">
            <h6>Featured</h6>
            <h2>Venues</h2>
            </div>
            <div className="hero-banner">
               <Link to={`/single-venue/${featuredVenue?.slug}`}><img width="" height="" src={config.BASE_URL + featuredVenue.featuredImage} alt="" /></Link>
            </div>
            <div className="hero-summary container-summary position-absolute center">
               <Link to={`/single-venue/${featuredVenue?.slug}`}>
                  <div className="container-summary-wrapper m-auto w-100">
                     <div className="title-block">{featuredVenue?.title}</div>
                     <div className="cursive-title-block organizer">{featuredVenue?.city}</div>
                     <div className="divider"></div>
                     <div className="title-block location">{featuredVenue?.state}</div>
                     <div className="text-block">{featuredVenue?.description}</div>
                  </div>
               </Link>
            </div>
         </div>
      )}
        <div className="venue-items py-100">
           <div className="container m-px-0">
              <div className="grid-row">
                <Swiper
                  
                  spaceBetween={20}
                  loop={true}
                  centeredSlides={true}                    
                  slidesPerView={1.4}
                  initialSlide={2}
                  breakpoints={{                                                
                  993:{   
                  loop: false,                                  
                  centeredSlides: false,                 
                  slidesPerView: 3,
                  spaceBetween: 30,
                  },
                  }}
                >
                  {(venues && venues.length) > 0 && venues.map((item, key) => (
                     <SwiperSlide key={key}>                  
                        <div className="grid-item">
                           <Link to={`/single-venue/${item?.slug}`}>
                              <div className="image-block"><img className="w-100" width="" height="" src={config.BASE_URL + item?.featuredImage} alt="" /></div>
                              <div className="title-block">{item?.title}</div>
                              <div className="cursive-title-block organizer">{item?.city}</div>
                              <div className="divider"></div>
                              <div className="title-block location">{item?.state}</div>
                           </Link>
                        </div>
                     </SwiperSlide>
                  ))}
                </Swiper>
                </div>
           </div>
        </div>
     </div>
  </Layout>
  );
  };

export default Home;
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import galleryImg1 from "../assets/images/gallery-img-1.jpg";
import galleryImg2 from "../assets/images/gallery-img-2.jpg";
import galleryImg3 from "../assets/images/gallery-img-3.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import venueIcon from "../assets/images/venue-icon.svg";

import config from '../config';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Lightbox from "react-image-lightbox"; // Import Lightbox
import "react-image-lightbox/style.css";

const SingleEvent = () => {

    const [isOpen, setIsOpen] = useState(false); // Lightbox state
    const [currentImage, setCurrentImage] = useState(""); // Current image state
    const [event, setEvent] = useState();
    const {id} = useParams();

    const [activeTab, setActiveTab] = useState("venue");

    const venueRef = useRef(null);
    const itineraryRef = useRef(null);
    const galleryRef = useRef(null);

    const images = [galleryImg1, galleryImg2, galleryImg3]; // Array of images

    const handleImageClick = (image) => {
        setCurrentImage(image); // Set the clicked image
        setIsOpen(true); // Open the lightbox
    };

    const fetchEvent = async () => {
        try {
           const response = await axios.get(`${config.BASE_URL}/api/admin/single-event/${id}`, { withCredentials: true });
           
           if(response.data){
                setEvent(response.data);
           }
  
        } catch (err) {
           console.log('Failed to fetch events');
        }
    };

    const toggleTab = (e, tabName, ref) => {
        e.preventDefault();
  
        setActiveTab(tabName);
        if (ref.current) {
           ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
     }

    useEffect(() => {
        if(id){
            fetchEvent();
        }
     }, []);

     if(!event) return null;

    return (
        <Layout extraClass={"single-event"}>
            <>
                <div className="hero-section grid-item events-section container-hero-section d-flex justify-content-center top-bottom-overlay position-relative">
                    <div className="hero-banner">
                        <img width="" height="" src={config.BASE_URL + event.featuredImage} alt="" />
                    </div>
                    <div className="hero-summary container-summary position-absolute center color-light">
                        <div className="title-block">{event?.title}</div>
                        <div className="divider position-relative">at</div>
                        <div className="summary-wrapper">
                            <div className="logo-block"><img width="" height="" src={venueIcon} alt="" /></div>
                            <div className="title-block location">{event?.venueTitle}</div>
                            <div className="cursive-title-block organizer">{event?.venueLocation}</div>
                        </div>
                    </div>
                </div>

                <div className="image-overlap-sidetext-section py-180 m-pt-120 m-pb-120">
                    <div className="container">
                        <div className="container-500 m-auto">
                            <div className="title-block center m-auto pb-50">
                                <h5>{event?.centerContent?.topText}</h5>
                            </div>
                            <div className="image-block position-relative">
                                <img width="" height="" src={config.BASE_URL + event?.centerContentImage} alt="" />
                                <div className="text text-left cursive-title-block position-absolute">{event?.centerContent?.leftText}</div>
                                <div className="text text-right cursive-title-block position-absolute">{event?.centerContent?.rightText}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="title-with-line">
                    <div className="container">
                        <div className="title-with-line-wrapper"><h3 className="cursive-title-block">details</h3></div>
                    </div>
                </div>

                <div className="tab-section-scroll">
                    <ul className="list-style-none d-flex justify-content-center align-items-end">
                        <li><a href="#event-spaces" onClick={(e) => toggleTab(e, "venue", venueRef)} className={`${activeTab === "venue" ? 'active' : ''}`}>Venue</a></li>
                        <li><a href="#accomodation" onClick={(e) => toggleTab(e, "itinerary", itineraryRef)} className={`${activeTab === "itinerary" ? 'active' : ''}`}>Itinerary</a></li>
                        <li><a href="#near-by" onClick={(e) => toggleTab(e, "gallery", galleryRef)} className={`${activeTab === "gallery" ? 'active' : ''}`}>Gallery</a></li>
                    </ul>
                </div>

                <div id="venue" className="hero-section full-screen d-flex justify-content-center align-items-end bottom-150 bottom-overlay-dark position-relative" ref={venueRef}>
                    <div className="hero-banner">
                        <img width="" height="" src={config.BASE_URL + event?.venueBannerImage} alt="" />
                    </div>
                    <div className="hero-summary center color-light position-absolute">
                        <div className="title-block"><h2 className="mb-0">{event?.venueBannerTitle}</h2></div>
                        <div className="text-block">{event?.venueBannerDesc}</div>
                    </div>
                </div>

                <div className="image-column-section bg-black color-light center py-100">
                    <div className="container m-px-0">
                        <div className="grid-row d-grid">
                            <div className="grid-item grid-small position-relative">
                                <img width="" height="" src={config.BASE_URL + event?.venueTabLeftImage} alt="" />
                                <div className="text text-right position-absolute">
                                    <h3 className="cursive-title-block text-no-wrap">{event?.venueTabTitle}</h3>
                                </div>
                            </div>
                            <div className="grid-item grid-large">
                                <img width="" height="" src={config.BASE_URL + event?.venueTabRightImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="richtext color-light center bg-black pb-100">
                    <div className="container">
                        <div className="richtext-block m-auto">
                            <p>{event?.venueTabDesc}</p>
                        </div>
                    </div>
                </div>

                <div id="itinerary" className="itinerary-section color-light center bg-black pb-150 m-pb-100" ref={itineraryRef}>
                    <div className="container m-px-0">
                        <div className="title-block pb-50 m-pb-30">
                            <h2>Itinerary</h2>
                        </div>
                        <div className="grid-row">
                            <Swiper
                                spaceBetween={25}
                                loop={true}
                                centeredSlides={true}
                                initialSlide={2}
                                slidesPerView={1.4}
                                breakpoints={{
                                    1024: {
                                        loop: false,
                                        centeredSlides: false,
                                        slidesPerView: 3,
                                        spaceBetween: 30,

                                    },
                                }}
                            >
                                {(event?.itineraryContent && event?.itineraryContent.length > 0) && event?.itineraryContent.map((item, key) => (
                                    <SwiperSlide>
                                        <div className="grid-item">
                                            <div className="image-block">
                                                <img width="" height="" src={config.BASE_URL + item?.itineraryImage} alt="" />
                                            </div>
                                            <div className="title-block">
                                                <h5>{item?.title}</h5>
                                            </div>
                                            <div className="text-block">{item?.description}</div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>

                {event && (
                    <div className="hero-section d-flex justify-content-center align-items-center position-relative">
                        <div className="hero-banner">
                            <picture>
                                <source media="(max-width:768px)" srcSet={event?.venueBannerImage2 && config.BASE_URL + event?.venueBannerImage2} />
                                <img width="" height="" src={event?.venueBannerImage2 && config.BASE_URL + event?.venueBannerImage2} alt="" />
                            </picture>
                        </div>
                        <div className="hero-summary color-light position-absolute m-px-20">
                            <div className="title-block"><h2>{event?.venueBannerTitle2}</h2></div>
                            <div className="text-block"><h3 className="cursive-title-block">{event?.venueBannerDesc2}</h3></div>
                        </div>
                    </div>
                )}

                <div id="gallery" className="gallery-section color-light center m-py-50" ref={galleryRef}>
                    <div className="grid-row equal-height pb-0">
                        <Swiper
                            spaceBetween={25}
                            loop={true}
                            centeredSlides={true}
                            initialSlide={2}
                            slidesPerView={1.4}
                            breakpoints={{
                                1024: {
                                    loop: false,
                                    centeredSlides: false,
                                    slidesPerView: 3,
                                    spaceBetween: 0,
                                },
                            }}
                        >
                            {event?.galleryImages?.length > 0 && event?.galleryImages.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div
                                        className="grid-item image-block"
                                        onClick={() => handleImageClick(config.BASE_URL + item)} // Open lightbox on click
                                        style={{ cursor: "pointer" }}
                                    >
                                        <img width="" height="" src={config.BASE_URL + item} alt={`Gallery image ${index + 1}`} />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                {/* Lightbox Component */}
                {isOpen && (
                    <Lightbox
                        mainSrc={currentImage}
                        onCloseRequest={() => setIsOpen(false)} // Close the lightbox
                    />
                )}

            </>
        </Layout>
    );
};

export default SingleEvent;
import React, { useEffect, useRef } from "react";

const VideoElement = (props) => {
  const { url } = props;
  return (
    <>
      <video loop={true} autoPlay={true} muted={true} playsInline={true}>
        <source src={`${url}`} type="video/mp4" />
      </video>
    </>
  );
};

export default VideoElement;

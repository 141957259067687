import Layout from "./Layout";
import AboutImage from "../assets/images/about-image.jpg";
import AboutImageBanner from "../assets/images/about-image-banner.jpg";
import ArrowDark from "../assets/images/arrow-dark.svg";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
const About = () => { 

  return (
    <Layout showFooter={false}>
        <div className="page-template template-about pt-220">
            <div className="title-block center pb-40" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                <div className="container">
                    <h1>About</h1>
                </div>
            </div>
            <div className="image-overlap-sidetext-section" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <div className="container">
                    <div className="container-500 m-auto">
                        <div className="title-block center m-auto pb-50">
                            <h5>The Unmatched Experience <span className="d-block">Behind Caledonia Events</span></h5>
                        </div>
                        <div className="image-block position-relative">
                            <img width="" height="" src={AboutImage} alt="" />
                            <div className="text text-left cursive-title-block position-absolute">Scotland is our home</div>
                            <div className="text text-right cursive-title-block position-absolute">stories by hear</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="richtext py-100 m-pb-80" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <div className="container">                    
                    <div className="summary-block center">
                        <p>Caledonia Events is the vision brought to life by Jamie Mangan and Nicholas Webber. Both have  contributed well over their 10,000 hours to the international events industry and bring their  world-leading expertise to every event from weddings to brand launches. Their complementary skill sets span event design, production, management, and delivery, with a refined focus on guest  experience that is unrivalled in Scotland. With decades of global experience and connections,  Jamie and Nick plan one-of-a-kind parties defined by authenticity and excellence, made possible by their love for, and deep insight into, Scotland.</p>
                    </div>                    
                </div>
            </div>
            <div className="image-banner  d-flex justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <img className="w-100" width="" height="" src={AboutImageBanner} alt="" />
                <div className="summary-block color-light center position-absolute" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                    <div className="container">
                       <div className="text-block">
                        <p>“Scotland is our home, and we know its landscapes, traditions, and stories by heart. With a lifetime  spent delivering destination parties and weddings worldwide, we’re uniquely positioned to blend  our local knowledge with global expertise, designing an event for you in Scotland that is as  sophisticated as it is soulful.”</p>
                        <div className="mangonda-title-block font-35 pt-20">C.E.</div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="get-in-touch pt-80 pb-90 m-pt-70 m-pb-80">
                <div className="container" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                    <div className="summary-block center">
                        <p>Begin planning your next Scotland experience with us.</p>
                    </div>
                    <div className="cta-block-wrapper center pt-40">
                        <div className="cta-text m-auto pb-20 mb-30">Get In Touch</div>
                        <div className="cta-block d-flex justify-content-center"><Link to="/contact">start <img width="" height="" src={ArrowDark} alt="" /></Link></div>
                    </div>
                </div>
            </div>
        </div>        
    </Layout>
  );
};

export default About;
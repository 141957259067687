import React, { useState } from "react";
import { Link } from "react-router-dom";

const FooterBottom = () => { 

  return (    
    <div className="footer-bottom color-light bg-black py-20">
        <div className="container">
            <ul className="d-flex justify-content-center">
                <li><Link to="#">Terms & Conditions</Link></li>
                <li><Link to="#">Privacy Policy</Link></li>
            </ul>
        </div>
    </div>
  );
};

export default FooterBottom;
import Layout from "./Layout";
import venueIcon from "../assets/images/venue-icon.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import VideoElement from "../components/VideoElement";
import config from '../config';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

const SingleVenue = () => {

  const [venue, setVenue] = useState();
  const { id } = useParams();
  const [venues, setVenues] = useState([]);

  const [activeTab, setActiveTab] = useState("eventSpaces");

  const eventSpacesRef = useRef(null);
  const accomodationRef = useRef(null);
  const nearByRef = useRef(null);

  const fetchVenue = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/admin/single-venue/${id}`, { withCredentials: true });

      if (response.data) {
        setVenue(response.data);
      }

    } catch (err) {
      console.log('Failed to fetch events');
    }
  };

  const fetchVenues = async () => {
    try {
      const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });

      if (response.data) {
        setVenues(response.data);
      }

    } catch (err) {
      console.log('Failed to fetch venues');
    }
  };

  const renderImageOrVideo = (src) => {
    const extension = src.split(".").pop();
    var mediaType = "";
    const videoExtensions = [
      "mp4",
      "3gp",
      "avi",
      "mkv",
      "webm",
      "mov",
      "flv",
      "wmv",
      "mpeg",
      "mpg",
      "m4v",
    ];
    const imageExtensions = [
      "jpg",
      "jfif",
      "avif",
      "jpeg",
      "svg",
      "png",
      "ico",
      "gif",
      "bmp",
      "webp",
      "tiff",
      "heic",
    ];

    if (videoExtensions.includes(extension)) {
      mediaType = "VIDEO";

      return (
        <VideoElement url={src} />
      );
    } else if (imageExtensions.includes(extension)) {
      mediaType = "IMAGE";

      return (
        <img src={src} alt="accomodation" />
      );
    }
  }

  const toggleTab = (e, tabName, ref) => {
    e.preventDefault();

    setActiveTab(tabName);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // console.log("venue?.nearByMapImage: ", venue?.nearByMapImage);

  useEffect(() => {
    if (id) {
      fetchVenue();
    }

    fetchVenues();
  }, []);

  if (!venue) return null;

  return (
    <Layout extraClass={"single-venue"}>
      <div className="hero-section container-hero-section d-flex justify-content-center top-bottom-overlay position-relative">
        <div className="hero-banner">
          <img width="" height="" src={config.BASE_URL + venue.featuredImage} alt="" />
        </div>
        <div className="hero-summary container-summary position-absolute center color-light">
          <div className="container-summary-wrapper m-auto w-100">
            <div className="logo-block top position-absolute"><img width="" height="" src={venueIcon} alt="" /></div>
            <div className="title-block"><h5>{venue?.title}</h5></div>
            <div className="organizer-block flawlush-font">{venue?.city}</div>
            <div className="divider m-auto"></div>
            <div className="location-block"><h6>{venue?.state}</h6></div>
            <div className="text-block">{venue?.description}</div>
          </div>
        </div>
      </div>


      <div className="image-overlap-sidetext-section py-180 m-pt-120 m-pb-100">
        <div className="container">
          <div className="container-500 m-auto">
            <div className="title-block center m-auto pb-50">
              <h5>{venue?.centerContent && JSON.parse(venue?.centerContent).topText}</h5>
            </div>

            <div className="image-block position-relative">
              <img width="" height="" src={config.BASE_URL + venue?.centerContentImage} alt="" />
              <div className="text text-left cursive-title-block position-absolute">{venue?.centerContent && JSON.parse(venue?.centerContent).leftText}</div>
              <div className="text text-right cursive-title-block position-absolute">{venue?.centerContent && JSON.parse(venue?.centerContent).rightText}</div>
            </div>
            <div className="bottom-text center d-flex justify-content-center m-auto w-100 pt-60">
              <div className="text-item">
                <div className="title-block">Best For</div>
                <div className="text-block">{venue?.bestFor}</div>
              </div>
              <div className="text-item">
                <div className="title-block">Best Season</div>
                <div className="text-block">{venue?.bestSeason}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="title-with-line">
        <div className="container">
          <div className="title-with-line-wrapper"><h3 className="cursive-title-block">details</h3></div>
        </div>
      </div>

      <div className="tab-section-scroll">
        <ul className="list-style-none d-flex justify-content-center align-items-end">
          <li><a href="#" onClick={(e) => toggleTab(e, "eventSpaces", eventSpacesRef)} className={`${activeTab === "eventSpaces" ? 'active' : ''}`}>Event Spaces</a></li>
          <li><a href="#" onClick={(e) => toggleTab(e, "accomodation", accomodationRef)} className={`${activeTab === "accomodation" ? 'active' : ''}`}>Accomodation</a></li>
          {venue?.nearByMapImage && (
            <li><a href="#" onClick={(e) => toggleTab(e, "nearBy", nearByRef)} className={`${activeTab === "nearBy" ? 'active' : ''}`}>Neary-by</a></li>
          )}
        </ul>
      </div>

      <div ref={eventSpacesRef} className="hero-section full-screen d-flex justify-content-center bottom-100 bottom-overlay-dark position-relative">
        <div className="hero-banner">
          <img width="" height="" src={config.BASE_URL + venue?.eventSpaceBannerImage} alt="" />
        </div>
        <div className="hero-summary center color-light position-absolute">
          <div className="title-block"><h2 className="mb-0">{venue?.eventSpaceBannerTitle}</h2></div>
          <div className="text-block">{venue?.eventSpaceBannerDesc}</div>
        </div>
      </div>

      <div className="image-column-section bg-black color-light center py-100 m-pt-50">
        <div className="container m-px-0">
          <div className="grid-row d-grid">
            <div className="grid-item grid-small center position-relative">
              <img width="" height="" src={config.BASE_URL + venue?.eventSpaceLeftImage} alt="" />
              <div className="text text-right position-absolute">
                <div className="cursive-title-block text-no-wrap">{venue?.eventSpaceTitle1}</div>
              </div>
            </div>
            <div className="grid-item grid-large position-relative">
              <img width="" height="" src={config.BASE_URL + venue?.eventSpaceRightImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="richtext color-light center bg-black pb-100 m-pb-70">
        <div className="container">
          <div className="richtext-block m-auto pb-80 w-100">
            <p>{venue?.eventSpaceDesc1}</p>
          </div>
          <div className="image-block">
            <picture>
              <source media="(max-width:767px)" srcset={config.BASE_URL + venue?.eventSpaceCenterImage} />
              <img width="" height="" src={config.BASE_URL + venue?.eventSpaceCenterImage} alt="" />
            </picture>
          </div>
          <div className="richtext-block m-auto pt-100 m-pt-70 w-100">
            <h4 className="cursive-title-block m-pb-30">{venue?.eventSpaceTitle2}</h4>
            <p>{venue?.eventSpaceDesc2}</p></div>
        </div>
      </div>

      <div id="accomodation" ref={accomodationRef} className="hero-section accomodation-hero-section full-screen d-flex justify-content-center align-items-center position-relative">
        <div className="hero-banner">
          {/* <img width="" height="" src={config.BASE_URL + venue?.accomodationImage}  alt="" /> */}
          {renderImageOrVideo(config.BASE_URL + venue?.accomodationImage)}
          {/* <video loop={true} autoPlay={true} muted={true} playsInline={true}>
            <source src={config.BASE_URL + venue?.accomodationImage} />
          </video> */}
        </div>
        <div className="hero-summary color-light position-absolute">
          <div className="title-block pb-30"><h2 className="mb-0">Accomodation</h2></div>
          <div className="title-block"><h3 className="cursive-title-block">{venue?.description}</h3></div>
        </div>
      </div>

      <div className={`location-section ${(venue && (venue?.nearByMapImage || venue?.itineraryContent.length > 0 )) ? "py-150 m-py-80" : ""}`} ref={nearByRef}>
        <div className="container m-px-0">
          {venue?.nearByMapImage && (
            <div className="d-flex justify-content-center">
              <div className="location-wrapper w-100">
                <div className="title-block pb-40"><h2>Nearby {venue?.title}</h2></div>
                <div className="map-wrapper m-auto">
                  <div className="map-inner position-relative m-auto w-100">
                    <img width="" height="" src={config.BASE_URL + venue?.nearByMapImage} alt="" />
                    <div className="map-summary d-flex flex-column align-items-end position-absolute w-100">
                      <div className="map-info center w-100">
                        <div className="title-block map-address">{venue?.title}</div>
                        <div className="map-more"><a target="_blank" href={`https://maps.google.com/?q=${venue?.title}, ${venue?.city}, ${venue?.state}, ${venue?.country}`}>view on map</a></div>
                      </div>
                      <div className="map-organizer">
                        <span>{venue?.city}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`location-items center ${venue?.nearByMapImage ? "pt-150" : ""} m-pt-100`}>
            <div className="grid-row">
              <Swiper
                spaceBetween={20}
                loop={true}
                centeredSlides={true}
                initialSlide={2}
                slidesPerView={1.4}
                breakpoints={{
                  993: {
                    loop: false,
                    centeredSlides: false,
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >

                {venue && venue?.itineraryContent.length > 0 && venue?.itineraryContent.map((item, key) => (
                  <SwiperSlide>
                    <div className="grid-item">
                      <div className="image-block"><img width="" height="" src={config.BASE_URL + item?.image} alt="" /></div>
                      <div className="title-block">{item?.title}</div>
                      <div className="text-block">{item?.description}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default SingleVenue;
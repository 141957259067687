import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from '../../config';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

function UpdateEventDetail() {
    const { id } = useParams(); // Get the project ID from the URL
    const navigate = useNavigate();
    const [event, setEvent] = useState({ 
        title: '',
        venueId: '',
        overlayText: '',
        centerContent: ''
    });

    const [newImages, setNewImages] = useState([]);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [centerContentImage, setCenterContentImage] = useState(null);

    const [venueBannerImage, setVenueBannerImage] = useState(null);
    const [venueTabLeftImage, setVenueTabLeftImage] = useState(null);
    const [venueTabRightImage, setVenueTabRightImage] = useState(null);
    const [venueBannerImage2, setVenueBannerImage2] = useState(null);

    const [featuredVideo, setFeaturedVideo] = useState(null);
    const [venues, setVenues] = useState([]);

    const featuredImageRef = useRef(null); 
    const bannerImageRef = useRef(null); 
    const centerContentImageRef = useRef(null); 
    const videoRef = useRef(null); 

    const venueBannerImageRef = useRef(null); 
    const venueTabLeftImageRef = useRef(null); 
    const venueTabRightImageRef = useRef(null); 
    const venueBannerImage2Ref = useRef(null); 

    const fileInputRef = useRef(null);
    
    const [removeImages, setRemoveImages] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); 

    const [itineraryContent, setItineraryContent] = useState([
        { title: '', description: '', itineraryImage: null }
    ]);
    
    const handleItineraryChange = (index, event) => {
        const newFields = itineraryContent.map((field, fieldIndex) => {
          if (index === fieldIndex) {
            return { ...field, [event.target.name]: event.target.value };
          }
          return field;
        });
        setItineraryContent(newFields);
    };
    
    const handleItineraryImageChange = (index, event) => {
        const image = event.target.files;
        const newFields = itineraryContent.map((field, fieldIndex) => {
          if (index === fieldIndex) {
            return { ...field, [event.target.name]: image[0] };
          }
          return field;
        });
        setItineraryContent(newFields);
    };
    
    const addItineraryFields = () => {
        setItineraryContent([...itineraryContent, { title: '', description: '', itineraryImage: null }]);
    };
    
      const removeItineraryFields = (index) => {
        const newFields = itineraryContent.filter((field, fieldIndex) => index !== fieldIndex);
        setItineraryContent(newFields);
    };

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await axios.get(`${config.BASE_URL}/api/admin/get-event/${id}`, { withCredentials: true });
                setEvent(response.data); // Set the fetched project details
                fetchVenueDetail(response.data?.venueId);
                setItineraryContent(response.data?.itineraryContent)
            } catch (err) {
                setError('Failed to fetch location details');
            }
        };

        fetchEventDetails();
    }, [id]);

    const fetchVenueDetail = async (venueId) => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-venue/${venueId}`, { withCredentials: true });
            setEvent((prevEvent) => {
                return {
                    ...prevEvent, 
                    venueId: response.data
                }
            });
            
        } catch (err) {
            console.log('Failed to fetch location details');
        } 
    };

    const fetchAllVenues = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
            
            if(response.data){
                setVenues(response.data);
            }
    
        } catch (err) {
            console.log('Failed to fetch venues');
        }
    };

    useEffect(() => {
        fetchAllVenues();
    }, []);

    const handleItineraryRemoveImage = async (imageName, index) => {
        await handleRemoveImage(imageName);

        const newFields = itineraryContent.map((field, fieldIndex) => {
            if (index === fieldIndex) {
              return { ...field, itineraryImage: null };
            }
            return field;
        });

        setItineraryContent(newFields);

    }

    const handleRemoveImage = async (imageName) => {
        try {
            const response = await axios.post(`${config.BASE_URL}/api/admin/remove-event-image`, { 
                eventId: event._id, 
                imageName 
            }, { withCredentials: true });
    
            if (response.status === 200) {
                // Remove image from the project state and track it for removal
                setRemoveImages(prev => [...prev, imageName]); // Add to removeImages array
                
                // Update project state
                setEvent(prevEvent => {
                    const updatedImages = prevEvent.galleryImages.filter(image => image !== imageName);
                    return {
                        ...prevEvent,
                        featuredImage: prevEvent.featuredImage === imageName ? null : prevEvent.featuredImage,
                        bannerImage: prevEvent.bannerImage === imageName ? null : prevEvent.bannerImage,
                        centerContentImage: prevEvent.centerContentImage === imageName ? null : prevEvent.centerContentImage,
                        venueBannerImage: prevEvent.venueBannerImage === imageName ? null : prevEvent.venueBannerImage,
                        venueTabLeftImage: prevEvent.venueTabLeftImage === imageName ? null : prevEvent.venueTabLeftImage,
                        venueTabRightImage: prevEvent.venueTabRightImage === imageName ? null : prevEvent.venueTabRightImage,
                        venueBannerImage2: prevEvent.venueBannerImage2 === imageName ? null : prevEvent.venueBannerImage2,
                        galleryImages: updatedImages,
                    };
                });
            } else {
                console.error('Failed to remove image');
            }
        } catch (err) {
            console.error('Error removing image:', err);
        }
    };

    const handleFeaturedImageChange = (e) => {
        setFeaturedImage(e.target.files[0]); // Set the featured image
    };

    const handleBannerImageChange = (e) => {
        setBannerImage(e.target.files[0]); // Set the featured image
    };

    const handleContentImageChange = (e) => {
        setCenterContentImage(e.target.files[0]); // Set the featured image
    };

    const handlevenueBannerImageChange = (e) => {
        setVenueBannerImage(e.target.files[0]); // Set the featured image
    };

    const handlevenueTabLeftImageChange = (e) => {
        setVenueTabLeftImage(e.target.files[0]); // Set the featured image
    };

    const handlevenueTabRightImageChange = (e) => {
        setVenueTabRightImage(e.target.files[0]); // Set the featured image
    };

    const handlevenueBannerImage2Change = (e) => {
        setVenueBannerImage2(e.target.files[0]); // Set the featured image
    };

    const handleNewImageChange = (e) => {
        setNewImages(e.target.files); // Set new images to be uploaded
    };

    const handleVideoChange = (e) => {
        setFeaturedVideo(e.target.files[0]); // Set new images to be uploaded
    };

    const handleImageUpload = async (image) => {
        if(typeof image !== "object")
            return false;

        const formData = new FormData();
        formData.append('image', image);
    
        try {
          const response = await axios.post(`${config.BASE_URL}/api/admin/upload-event-image`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          return response.data?.image; // Assuming your API returns the image URL
        } catch (error) {
          console.error('Image upload failed:', error);
          return null;
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        var updatedItinerary = [];

        if(itineraryContent.length > 0) {
        await Promise.all(itineraryContent.map(async (item, index) => {
            if(item?.itineraryImage){
                const imageData = await handleImageUpload(item.itineraryImage);
                
                updatedItinerary.push({title: item.title, description: item.description, itineraryImage: imageData ? imageData : item.itineraryImage });
            }else{
                updatedItinerary.push({title: item.title, description: item.description, itineraryImage: null });
            }
        }));
        }

        const formData = new FormData();
        formData.append('title', event.title);
        formData.append('venueId', event.venueId?._id);
        formData.append('overlayText', event.overlayText);

        formData.append('venueTitle', event?.venueTitle);
        formData.append('venueLocation', event?.venueLocation);
        formData.append('venueBannerTitle', event?.venueBannerTitle);
        formData.append('venueBannerDesc', event?.venueBannerDesc);
        formData.append('venueTabTitle', event?.venueTabTitle);
        formData.append('venueTabDesc', event?.venueTabDesc);
        formData.append('venueBannerTitle2', event?.venueBannerTitle2);
        formData.append('venueBannerDesc2', event?.venueBannerDesc2);

        formData.append('centerContent', JSON.stringify(event?.centerContent));
        formData.append('itineraryContent', JSON.stringify(updatedItinerary));

        // Add the featured image if selected
        if (featuredImage) {
            formData.append('featuredImage', featuredImage);
        }

        if (bannerImage) {
            formData.append('bannerImage', bannerImage);
        }

        if (featuredVideo) {
            formData.append('featuredVideo', featuredVideo);
        }

        if (centerContentImage) {
            formData.append('centerContentImage', centerContentImage);
        }

        if (venueBannerImage) {
            formData.append('venueBannerImage', venueBannerImage);
        }
    
        if (venueTabLeftImage) {
            formData.append('venueTabLeftImage', venueTabLeftImage);
        }
    
        if (venueTabRightImage) {
            formData.append('venueTabRightImage', venueTabRightImage);
        }
    
        if (venueBannerImage2) {
            formData.append('venueBannerImage2', venueBannerImage2);
        }

        for (const image of newImages) {
            formData.append('galleryImages', image);
        }

        try {
            const response = await axios.put(`${config.BASE_URL}/api/admin/update-event/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            setEvent(response.data.event);

            if (featuredImageRef.current) {
                featuredImageRef.current.value = '';
            }

            if (bannerImageRef.current) {
                bannerImageRef.current.value = '';
            }

            if (centerContentImageRef.current) {
                centerContentImageRef.current.value = '';
            }

            if (venueBannerImageRef.current) {
                venueBannerImageRef.current.value = '';
            }

            if (venueTabLeftImageRef.current) {
                venueTabLeftImageRef.current.value = '';
            }

            if (venueTabRightImageRef.current) {
                venueTabRightImageRef.current.value = '';
            }

            if (venueBannerImage2Ref.current) {
                venueBannerImage2Ref.current.value = '';
            }

            setFeaturedImage(null);
            setCenterContentImage(null);

            setVenueBannerImage(null);
            setVenueBannerImage2(null);
            setVenueTabLeftImage(null);
            setVenueTabRightImage(null);

            setBannerImage(null);

            setSuccessMessage('Event updated successfully!'); 

            navigate(`/admin/event/${id}`);
        } catch (err) {
            setError('Failed to update event');
        }
    };

    console.log("event.centerContent: ",event.centerContent)

    return (
        <div className="dashboard update_detail_wrap">
            <div className="container">
                <h1 className="dash_title">Edit Event</h1>
                <div className="back_btn_wrap">
                    <button className="back-btn" onClick={() => navigate(-1)}>Go Back</button>
                </div>
                {event ? (
                    <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                        <div className="update_details_wrap">
                            <div className="form-group">
                                <label>Event Title</label>
                                <input
                                    type="text"
                                    value={event.title}
                                    onChange={(e) => setEvent({ ...event, title: e.target.value })}
                                />
                            </div>

                            {/* <div className="form-group textareaField">
                                <h2>Venue</h2>
                                <Dropdown 
                                    value={event.venueId} 
                                    onChange={(e) => setEvent({...event, "venueId": e.value})} 
                                    options={venues} 
                                    optionLabel="title" 
                                    placeholder="Select Venue" 
                                    className="w-full md:w-14rem" 
                                />
                            </div> */}

                            <div className="form-group">
                                <label>Venue Title</label>
                                <input
                                    type="text"
                                    value={event.venueTitle}
                                    onChange={(e) => setEvent({ ...event, venueTitle: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Venue Location</label>
                                <input
                                    type="text"
                                    value={event.venueLocation}
                                    onChange={(e) => setEvent({ ...event, venueLocation: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Overlay Text</label>
                                <input
                                    type="text"
                                    value={event.overlayText}
                                    onChange={(e) => setEvent({ ...event, overlayText: e.target.value })}
                                />
                            </div>
                                
                            
                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Featured Image</label>
                                            <input type="file" onChange={handleFeaturedImageChange} ref={featuredImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.featuredImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.featuredImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.featuredImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Banner Image</label>
                                            <input type="file" onChange={handleBannerImageChange} ref={bannerImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.bannerImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.bannerImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.bannerImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Featured Video</label>
                                            <input type="file" onChange={handleVideoChange} ref={videoRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.featuredVideo ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap videoWrap">
                                                        <video>
                                                            <source src={`${config.BASE_URL}${event.featuredVideo}`} />
                                                        </video>
                                                        {/* <img src={`${config.BASE_URL}${event.featuredVideo}`} alt="Featured" /> */}
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.featuredVideo)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No video available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-group">
                                    <label>Upload Gallery Images</label>
                                    <input type="file" multiple onChange={handleNewImageChange} ref={fileInputRef} />
                                </div>
                                <div className="detail_item_inner">
                                <div className="detail_img_grid">
                                    {event?.galleryImages ? event.galleryImages.map((image, index) => (
                                        <div className="detail_image_item" key={index}>
                                            <div className="detail_img_item">
                                                <img src={`${config.BASE_URL}${image}`} alt={`event ${index}`} />
                                            </div>
                                            <button className="remove_btn" type="button" onClick={() => handleRemoveImage(image)}>Remove</button>
                                        </div>
                                    )) : (
                                        <p>No Gallery images available.</p>
                                    )}
                                </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <h2>Center Content</h2>
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Image</label>
                                            <input type="file" onChange={handleContentImageChange} ref={centerContentImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.centerContentImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.centerContentImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.centerContentImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Top Text</label>
                                <input
                                    type="text"
                                    value={event?.centerContent?.topText}
                                    onChange={(e) => setEvent({ ...event, centerContent: {...event.centerContent, topText: e.target.value}})}
                                />
                            </div>

                            <div className="form-group">
                                <label>Left Text</label>
                                <input
                                    type="text"
                                    value={event?.centerContent?.leftText}
                                    onChange={(e) => setEvent({ ...event, centerContent: {...event.centerContent, leftText: e.target.value}})}
                                />
                            </div>

                            <div className="form-group">
                                <label>Right Text</label>
                                <input
                                    type="text"
                                    value={event?.centerContent?.rightText}
                                    onChange={(e) => setEvent({ ...event, centerContent: {...event.centerContent, rightText: e.target.value}})}
                                />
                            </div>

                            <div className="form-group add_field itineraryFields">
                                <h1>Itinerary</h1>
                                    {itineraryContent.map((field, index) => (
                                    <div key={index} className="addItineraryFields">
                                        <div className="add_field">
                                        <h2>Title</h2>
                                        <input
                                            name="title"
                                            type="text"
                                            value={field.title}
                                            placeholder="Title"
                                            onChange={(event) => handleItineraryChange(index, event)}
                                        />
                                        </div>
                                        
                                        <div className="add_field">
                                        <h2>Description</h2>
                                        <input
                                            name="description"
                                            type="text"
                                            placeholder="Description"
                                            value={field.description}
                                            onChange={(event) => handleItineraryChange(index, event)}
                                        />
                                        </div>

                                        <div className="add_field">
                                            <div className="form-group">
                                                <div className="detail_banner_wrap">
                                                    <div className="detail_img_grid">
                                                        <div className="upload_item">
                                                            <label>Image</label>
                                                            <input type="file" name="itineraryImage" onChange={(event) => handleItineraryImageChange(index, event)} />
                                                        </div>

                                                        <div className="detail_img_grid">
                                                            {!field.itineraryImage?.name && field.itineraryImage ? (
                                                                <div className="detail_img_item">
                                                                    <div className="banner_img_wrap">
                                                                    <img src={`${config.BASE_URL}${field.itineraryImage}`} alt="Featured" />
                                                                    </div>
                                                                    <button className="remove_btn" type="button" onClick={() => handleItineraryRemoveImage(field.itineraryImage, index)}>Remove</button>
                                                                </div>
                                                            ) : (
                                                                <p>No image available.</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="button" onClick={() => removeItineraryFields(index)}>
                                        Remove
                                        </button>
                                    </div>
                                    ))}

                                    <button type="button" onClick={addItineraryFields}>Add More Itinerary</button>
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Venue Banner Image</label>
                                            <input type="file" onChange={handlevenueBannerImageChange} ref={venueBannerImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.venueBannerImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.venueBannerImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.venueBannerImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Venue Banner Title</label>
                                <input
                                    type="text"
                                    value={event.venueBannerTitle}
                                    onChange={(e) => setEvent({ ...event, venueBannerTitle: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Venue Banner Desc</label>
                                <input
                                    type="text"
                                    value={event.venueBannerDesc}
                                    onChange={(e) => setEvent({ ...event, venueBannerDesc: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Venue Tab Left Image</label>
                                            <input type="file" onChange={handlevenueTabLeftImageChange} ref={venueTabLeftImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.venueTabLeftImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.venueTabLeftImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.venueTabLeftImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Venue Tab Right Image</label>
                                            <input type="file" onChange={handlevenueTabRightImageChange} ref={venueTabRightImageRef} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.venueTabRightImage ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.venueTabRightImage}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.venueTabRightImage)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Venue Tab Title</label>
                                <input
                                    type="text"
                                    value={event.venueTabTitle}
                                    onChange={(e) => setEvent({ ...event, venueTabTitle: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Venue Tab Desc</label>
                                <input
                                    type="text"
                                    value={event.venueTabDesc}
                                    onChange={(e) => setEvent({ ...event, venueTabDesc: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <div className="detail_banner_wrap">
                                    <div className="detail_img_grid">
                                        <div className="upload_item">
                                            <label>Gallery Banner Image</label>
                                            <input type="file" onChange={handlevenueBannerImage2Change} ref={venueBannerImage2Ref} />
                                        </div>

                                        <div className="detail_img_grid">
                                            {event.venueBannerImage2 ? (
                                                <div className="detail_img_item">
                                                    <div className="banner_img_wrap">
                                                    <img src={`${config.BASE_URL}${event.venueBannerImage2}`} alt="Featured" />
                                                    </div>
                                                    <button className="remove_btn" type="button" onClick={() => handleRemoveImage(event.venueBannerImage2)}>Remove</button>
                                                </div>
                                            ) : (
                                                <p>No image available.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Gallery Banner Title</label>
                                <input
                                    type="text"
                                    value={event.venueBannerTitle2}
                                    onChange={(e) => setEvent({ ...event, venueBannerTitle2: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Gallery Banner Description</label>
                                <input
                                    type="text"
                                    value={event.venueBannerDesc2}
                                    onChange={(e) => setEvent({ ...event, venueBannerDesc2: e.target.value })}
                                />
                            </div>
                    
                            <div className="update_btn_wrap">
                                <button className="update_btn" type="submit">Update Event</button>
                            </div>

                            {successMessage && <p className="success-message">{successMessage}</p>}

                            {error && <p className="error-message">{error}</p>}
                        
                        </div>
                    </form>
                ) : (
                    <h5>Event not found!</h5>
                )}
            </div>
        </div>
    ); 
}

export default UpdateEventDetail;

import { Link } from "react-router-dom";
import Layout from "./Layout";
import venueIcon from "../assets/images/venue-icon.svg";
import config from '../config';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";

const VenueIndex = () => {    
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isFiltering, setIsFiltering] = useState(false);
    const [isFilterSticky, setIsFilterSticky] = useState(false);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [filterOffset, setFilterOffset] = useState(350);

    const [venues, setVenues] = useState([]);
    const [fullWidthVenue, setFullWidthVenue] = useState();

    const location = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }       
    ];
    const getOffsetFromTop = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const rect = element.getBoundingClientRect();
            const offset = rect.top + window.pageYOffset;
            return offset;
        }
        return null;
    };
    window.onscroll = function () {
        const scrollTop = document.documentElement.scrollTop;

        if (scrollTop > filterOffset) {
            setIsFilterSticky(true);
        } else {
            setIsFilterSticky(false);
        }
    }

    const fetchVenues = async () => {
        try {
           const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
           
            if(response.data){
                var locations = [];
                await Promise.all(response.data.length > 0 && response.data.map((item, key) => {
                    if(item.isFullWidth){
                        setFullWidthVenue(item);
                    }

                    locations.push({name: item?.city});
                }));

                setAvailableLocations(locations);
                setVenues(response.data);
            }
  
        } catch (err) {
            console.log('Failed to fetch venues');
        }
    };

    const filterVenues = async () => {
        try {
            const response = await axios.get(`${config.BASE_URL}/api/admin/get-venues`, { withCredentials: true });
            
             if(response.data){
                 var locations = [];
                 await Promise.all(response.data.length > 0 && response.data.map((item, key) => {
                     if(item.isFullWidth){
                         setFullWidthVenue(item);
                     }
 
                     locations.push({name: item?.city});
                 }));
 
                 setAvailableLocations(locations);
                 setVenues(response.data);
             }
   
         } catch (err) {
             console.log('Failed to fetch venues');
         }
    }

    useEffect(() => {
        fetchVenues();
    }, []);

    useEffect(() => {
        if(selectedLocation){
            setIsFiltering(true);
        }
    }, [selectedLocation]);

    useEffect(() => {
        const offset = getOffsetFromTop('venue-filter-section');
        setFilterOffset(offset);
    }, []);
    

  return (  
    <Layout>  
    <>
        <div className="venue-index template-index position-relative pt-220">            
            <div className="title-block center" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                <div className="container">
                    <h1>Venues</h1>
                </div>
            </div>
            {/* <div className="title-with-line">
              <div className="container">
                <div className="title-with-line-wrapper"><h3 className="flawlush-font">search</h3></div>
                </div>
            </div> */}

            {/* <div className={`filter-section position-sticky top-0 py-40 m-py-30 ${isFilterSticky ? "sticky" : ""}`} id="venue-filter-section">
                <div className="container">
                    <div className={`filter-items d-flex justify-content-center ${isFiltering ? "filterDisable" : ""}`}>
                        <div className="filter-item">
                            <Dropdown value={selectedLocation} onChange={(e) => setSelectedLocation(e.value)} options={availableLocations} optionLabel="name" 
                                placeholder="Location" className="w-full md:w-14rem" />
                        </div>
                        <div className="filter-item">
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={location} optionLabel="name" 
                                placeholder="Event Type" className="w-full md:w-14rem" />
                        </div>
                        <div className="filter-item">
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={location} optionLabel="name" 
                                placeholder="Property Type" className="w-full md:w-14rem" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="venues-section pt-50 m-pt-10 m-pb-0">
                <div className="container">
                    <div className="venue-listing">
                        {venues && venues.length > 0 && venues.map((item, index) => (
                            <div className="grid-item venue-item hero-section container-hero-section d-flex justify-content-center color-light position-relative" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                <div className="hero-banner">
                                    <Link to={`/single-venue/${item?.slug}`}><img width="" height="" src={config.BASE_URL + item.featuredImage} alt="" /></Link>
                                </div>
                                <div className="hero-summary container-summary center position-absolute">
                                    <Link to={`/single-venue/${item?.slug}`}>
                                        <div className="container-summary-wrapper m-auto w-100">
                                            <div className="logo-block top position-absolute"><img width="" height="" src={venueIcon} alt="" /></div>
                                            <div className="title-block">{item?.title}</div>
                                            <div className="cursive-title-block organizer">{item?.city}</div>
                                            <div className="divider"></div>
                                            <div className="title-block location">{item?.state}</div>
                                            <div className="text-block">{item?.description}</div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="text text-left cursive-title-block position-absolute">{item?.overlayText}</div>
                            </div>
                        ))}
                    </div>                    
                </div>
            </div>
            {/* {fullWidthVenue && (

                <div className="venue-hero-section video-section full-screen hero-section d-flex justify-content-center bottom-30 bottom-overlay-dark position-relative">
                    <div className="hero-banner playsinline">
                        <video loop={true} autoPlay={true} muted={true} playsInline={true}><source src={config.BASE_URL + fullWidthVenue?.featuredVideo} type="video/mp4" /></video>
                    </div>                
                    <div className="hero-summary d-flex flex-column justify-content-end align-items-center center color-light position-absolute h-100">
                        <div className="top-summary center color-light position-absolute">
                            <div className="logo-block"><img width="" height="" src={venue} alt="" /></div>     
                            <div className="title-block">{fullWidthVenue?.title}</div>
                        </div>
                        <div className="title-block"><h2>{fullWidthVenue?.description}</h2></div>                
                    </div>                
                </div>
            )} */}
        </div>
        
    </>
    </Layout>
  );
};

export default VenueIndex;